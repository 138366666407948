/**
 * @hidden
 */
export var DEFAULT_OFFSET = '16px';
/**
 * @hidden
 */
export var toStringValues = function (val) {
    if (typeof val === 'number') {
        return val + 'px';
    }
    return val;
};
/**
 * @hidden
 */
export var getAnchorAlign = function (fabAlign, rtl) {
    var align = { horizontal: (rtl ? 'right' : 'left'), vertical: 'bottom' };
    if (fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
    }
    return align;
};
/**
 * @hidden
 */
export var getPopupAlign = function (fabAlign, rtl) {
    var align = { horizontal: (rtl ? 'right' : 'left'), vertical: 'top' };
    if (fabAlign.horizontal === 'end') {
        align.horizontal = rtl ? 'left' : 'right';
    }
    return align;
};
/**
 * @hidden
 */
export var getTextDirectionClass = function (rtl, hAlign) {
    var al = hAlign === 'end' ? 'end' : 'start';
    var directions = {
        rtl: { end: 'k-text-left', start: 'k-text-right' },
        ltr: { start: 'k-text-left', end: 'k-text-right' }
    };
    return directions[rtl][al];
};
/**
 * @hidden
 */
export var position = function (ref, align, alignOffset, isRtl) {
    var horizontal = align.horizontal;
    var vertical = align.vertical;
    if (ref) {
        var xFab = alignOffset && alignOffset.x !== undefined ?
            toStringValues(alignOffset.x) :
            DEFAULT_OFFSET;
        var xCenterFab = alignOffset && alignOffset.x !== undefined ?
            "calc(50% + " + toStringValues(alignOffset.x) + ")" :
            '50%';
        var yFab = alignOffset && alignOffset.y !== undefined ?
            toStringValues(alignOffset.y) :
            DEFAULT_OFFSET;
        var yCenterFab = alignOffset && alignOffset.y !== undefined ?
            "calc(50% + " + toStringValues(alignOffset.y) + ")" :
            '50%';
        ref.style.setProperty(horizontalPosition(align, isRtl), horizontal === 'center' ? xCenterFab : xFab);
        ref.style.setProperty(verticalPosition(align), vertical === 'middle' ? yCenterFab : yFab);
        if (isRtl) {
            if ((vertical === 'top' || vertical === 'bottom') && horizontal === 'start') {
                ref.style.setProperty('left', 'unset');
            }
            if (vertical === 'middle' && horizontal === 'end') {
                ref.style.setProperty('right', 'unset');
            }
            if (vertical === 'middle' && horizontal === 'start') {
                ref.style.setProperty('left', 'unset');
            }
        }
    }
};
var horizontalPosition = function (align, isRtl) {
    var horizontal = align.horizontal;
    return {
        end: isRtl ? 'left' : 'right',
        center: 'left',
        start: isRtl ? 'right' : 'left'
    }[horizontal || 'end'];
};
var verticalPosition = function (align) {
    return {
        top: 'top',
        middle: 'top',
        bottom: 'bottom'
    }[align.vertical || 'bottom'];
};
