"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    props: {
        field: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        template: {
            type: [String, Function],
            default: undefined
        },
        headerTemplate: {
            type: [String, Function],
            default: undefined
        },
        width: {
            type: [String, Number],
            default: undefined
        }
    }
};